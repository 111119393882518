import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { BlockedApplicationModalComponent } from 'src/app/view/components/blocked-application-modal/blocked-application-modal.component';
import {
  Customer,
  Farm,
  Glebe,
  Trap,
  WebUser,
  Harvest,
  SystemSettings,
} from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { Timestamp, TimestampType } from '../interfaces/Types';

import firebase from 'firebase/compat/app';
import { Operation } from '../enums/Operation';
import { AreaType, Role, Theme } from '@tarvos-ag/tarvos-firestore-models/src/enums';
import { environment } from 'src/environments/environment';
import { GetWeatherChartArguments } from '../dto/get-weather-chart-arguments.dto';
import { GetWeatherChartResultDto } from '../dto/get-weather-chart-result.dto';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  private auth: firebase.User | null = null;
  private user: WebUser | null = null;
  private systemSettings: SystemSettings | null = null;
  private customer: Customer | null = null;
  private farm: Farm | null = null;
  private harvests: Array<Harvest> = [];
  private harvest: Harvest | null = null;
  private glebes: Array<Glebe> = [];
  private traps: Array<Trap> = [];

  private token: string | null = null;

  private subscribeToken!: Subscription;

  public updateComponentData: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  constructor(
    private db: AngularFirestore,
    private dialog: MatDialog,
    private fireAuth: AngularFireAuth,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {
    this.getAuthUser();
    this.getAuthToken();
  }

  /**
   * Este método obtém a autenticação do usuário logado
   */
  private getAuthUser(): void {
    this.fireAuth.user.subscribe((userAuth) => {
      this.auth = userAuth;
      if (this.auth) {
        this.getUserDoc();
      } else {
        this.customer = null;
        this.user = null;
        this.farm = null;
      }
    });
  }

  /**
   * Este método obtém o documento do usuário atual
   */
  private getUserDoc(): void {
    this.db
      .collection<WebUser>('users', (ref) => ref.where('id', '==', this.auth?.uid))
      .valueChanges()
      .subscribe((user) => {
        this.user = user[0] ? user[0] : null;
        if (this.user) {
          if (!this.user.lastSelectedCustomerId)
            if (this.user.customersAndFarms) {
              let firstCustomerAndFarms = Object.keys(this.user.customersAndFarms)[0];
              this.user.lastSelectedCustomerId = firstCustomerAndFarms;
            }
          if (!this.user.lastSelectedFarmId) {
            if (this.user.customersAndFarms) {
              let firstCustomerAndFarms = Object.keys(this.user.customersAndFarms)[0];
              if (
                this.user.customersAndFarms &&
                this.user.customersAndFarms[firstCustomerAndFarms].farmIds
              ) {
                this.user.lastSelectedFarmId =
                  this.user.customersAndFarms[firstCustomerAndFarms].farmIds![0];
              }
            }
          }
          //@ts-ignore
          if (!this.user.lastSelectedHarvestId) {
            this.getHarvestListDoc();
            this.user.lastSelectedHarvestId = this.harvests[0].id;
          }
          this.getCustomerDoc();
          this.getFarmDoc();
          this.getHarvestListDoc();
          this.getHarvestDoc();
          this.getSettingsDoc();
          this.setApplicationTheme(Theme.default);
          this.customerIsActive();
          this.getGlebeDocs();
          this.getTrapsDocs();
        }
      });
  }

  /**
   * Este método obtém o documento de cliente
   */
  private getCustomerDoc(): void {
    this.db
      .collection<any>(`/customers`)
      .doc(`${this.user?.lastSelectedCustomerId}`)
      .valueChanges()
      .subscribe(
        (data: any) => {
          this.customer = data;
        },
        () => {}
      );
  }

  /**
   * Este método obtém o documento de fazenda
   */
  private getFarmDoc(): void {
    this.db
      .collection<any>(`/customers/${this.user?.lastSelectedCustomerId}/farms`)
      .doc(this.user?.lastSelectedFarmId)
      .valueChanges()
      .subscribe(
        (data: any) => {
          this.farm = data;
        },
        () => {}
      );
  }

  /**
   * Este método obtém o documento a lista de safra
   */
  private getHarvestListDoc(): void {
    this.db
      .collection<Harvest>(
        `${this.getCustomerRefDatabase()}/farms/${this.user?.lastSelectedFarmId}/harvests`
      )
      .valueChanges()
      .subscribe((harvests: Array<any>) => {
        this.harvests = harvests;
      });
  }

  /**
   * Este método obtém o documento de safra atual
   */
  private getHarvestDoc(): void {
    this.db
      .collection<Harvest>(
        `${this.getCustomerRefDatabase()}/farms/${this.user?.lastSelectedFarmId}/harvests`
      )
      .doc<Harvest>(this.user?.lastSelectedHarvestId)
      .valueChanges()
      .subscribe((harvest: any) => {
        this.harvest = harvest;
      });
  }

  /**
   * Este método obtém as configurações do sistema
   */
  private getSettingsDoc(): void {
    this.db
      .collection<SystemSettings>(`${this.getHarvestRefDatabase()}/systemSettings`)
      .valueChanges()
      .subscribe((systemSettings: any) => {
        this.systemSettings = systemSettings[0] ? systemSettings[0] : null;
      });
  }

  /**
   * Este método obtém o token do usuário logado
   */
  private getAuthToken(): void {
    this.subscribeToken = this.fireAuth.idTokenResult.subscribe(
      (auth) => {
        if (auth && auth.token) {
          this.token = auth.token;
        } else {
          this.token = null;
        }
      },
      () => {
        this.token = null;
        this.subscribeToken.unsubscribe();
      }
    );
  }

  /**
   * Este método verifica se o cliente esta com os pagamentos em dia
   */
  private customerIsActive(): void {
    let dialogRef: any = null;
    this.db
      .collection<any>('/customers')
      .doc(
        this.user?.lastSelectedCustomerId != null ? this.user?.lastSelectedCustomerId : undefined
      )
      .valueChanges()
      .subscribe(
        (customer: any) => {
          if (
            customer?.status &&
            !customer.status.active &&
            (this.user?.role !== Role.admin || this.user?.support !== true)
          ) {
            const data = {
              closeModal: false,
              status: customer.status,
            };

            const dialog = this.dialog.getDialogById('alertModal');
            if (dialog) {
              dialog.componentInstance.data = data;
            } else {
              dialogRef = this.dialog.open(BlockedApplicationModalComponent, {
                data,
                disableClose: true,
                id: 'alertModal',
                width: '800px',
              });
            }
          } else if (dialogRef) {
            const whichRouteIsActive = this.route.children[0].children[0].component as any;
            this.updateComponentData.next('HeaderComponent');
            this.updateComponentData.next(whichRouteIsActive.name);
            this.updateComponentData.next(null);
            this.dialog.closeAll();
            dialogRef = null;
          }
        },
        () => {
          if (dialogRef) {
            dialogRef.unsubscribe();
          }
        }
      );
  }

  /**
   * Este método aplica o tema na aplicação
   * @param theme tema
   */
  public setApplicationTheme(theme: Theme): any {
    localStorage.setItem(Theme.localStorageKey, theme);
    this.updateComponentData.next('AppComponent');
    this.updateComponentData.next(null);
  }

  /**
   * Este método retorna a usuário atual
   */
  public getUser(): WebUser | null {
    return this.user;
  }

  /**
   * Este método retorna o cliente
   */
  public getCustomer(): Customer | null {
    return this.customer;
  }

  /**
   * Este método retorna a fazenda atual
   */
  public getFarm(): Farm | null {
    return this.farm;
  }

  /**
   * Este método retorna as configurações do sistema
   */
  public getSystemSettings(): SystemSettings | null {
    return this.systemSettings;
  }

  /**
   * Este método seta as configurações do sistema
   */
  public setSystemSettings(systemSettings: any): void {
    this.systemSettings = systemSettings ? systemSettings : null;
  }

  /**
   * Este método converte o valor da área conforme a unidade de medida
   * @param value valor em hectares
   * @returns a área correspondente a unidade de medida
   */
  public getCalculatedArea(value: number): number {
    if (this.systemSettings?.fieldArea === AreaType.square_meters) {
      return value * 10000;
    } else if (this.systemSettings?.fieldArea === AreaType.square_kilometers) {
      return value / 100;
    } else {
      return value;
    }
  }

  /**
   * This is method that convert the timestamp in data javascript
   */
  public getTimestampToDate(): Date {
    return Timestamp.now().toDate();
  }

  /**
   * Este método retorna 'true' se a safra foi colhida
   */
  public hasHarvested(): boolean {
    return this.harvest && this.harvest.harvested ? this.harvest.harvested : false;
  }

  /**
   * Este método retorna o token
   */
  public getToken(): string | null {
    return this.token;
  }

  /**
   * Este método retorna a data do servidor
   */
  public getServerDate(): TimestampType {
    return Timestamp.now();
  }

  /**
   * Este método retorna o id do cliente
   */
  public getCustomerId(): string {
    return this.user ? this.user.lastSelectedCustomerId : '';
  }

  /**
   * Este método retorna o id da fazenda atual
   */
  public getFarmId(): string {
    return this.user ? this.user.lastSelectedFarmId : '';
  }

  /**
   * Este método retorna o id da safra atual
   */
  public getHarvestId(): string {
    return this.user ? this.user.lastSelectedHarvestId : '';
  }

  /**
   * Este método retorna o id do usuário logado
   */
  public getUserId(): string {
    return this.auth ? this.auth.uid : '';
  }

  /**
   * Este método retorna o id do usuário logado
   */
  public getUserObservable(): Observable<WebUser | undefined> {
    return this.db.collection<WebUser>('users').doc(this.auth?.uid).valueChanges();
  }

  /**
   * Este método retorna a referencia de usuário no banco
   */
  public getUserRefDatabase(): string {
    return `users`;
  }

  /**
   * Este método retorna a referencia de cliente no banco
   */
  public getCustomerRefDatabase(): string {
    return `customers/${this.getCustomerId()}`;
  }

  /**
   * Este método retorna a referencia de fazenda no banco
   */
  public getFarmRefDatabase(): string {
    return `customers/${this.getCustomerId()}/farms/${this.getFarmId()}`;
  }

  /**
   * Este método retorna a referencia de safra no banco
   */
  public getHarvestRefDatabase(): string {
    return `customers/${this.getCustomerId()}/farms/${this.getFarmId()}/harvests/${this.getHarvestId()}`;
  }

  /**
   * Este método retorna uma lista de safra
   */
  public getHarvestList(): Array<Harvest> {
    return this.harvests;
  }

  /**
   * Este método retorna true para o tema dark
   */
  public getIsDarkTheme(): boolean {
    if (['/report', '/reportFruit'].includes(window.location.pathname)) {
      return false;
    } else {
      const theme = localStorage.getItem(Theme.localStorageKey);
      return theme === Theme.dark;
    }
  }

  /**
   * Este método verifica se o usuário ter permissão ou se a safra está finalizada
   * @param checkHarvestCompleted verificar se a safra está finalizada
   */
  public hasEditPermission(type: Operation, checkHarvestCompleted?: boolean): boolean {
    const { admin, operator, reader } = Role;
    const { create, read, update, remove, operation } = Operation;

    if (!this.user) return false;
    else {
      const userRole = this.user.role;
      switch (type) {
        case create:
          return [admin, operator].includes(userRole);
        case read:
          return [admin, operator, reader].includes(userRole);
        case update:
          return [admin].includes(userRole);
        case remove:
          return [admin].includes(userRole);
        case operation:
          return [admin, operator].includes(userRole);
        default:
          if (checkHarvestCompleted) {
            return !this.hasHarvested();
          }
          return true;
      }
    }
  }

  /**
   * Este método retorna uma lista de colunas de acordo com as permissões do usuário
   * @param displayedColumns lista de colunas
   * @param checkHarvestCompleted se é para verificar o status da safra
   */
  public filterColumnByPermission(
    displayedColumns: Array<string>,
    checkHarvestCompleted?: boolean
  ): Array<string> {
    if (this.hasEditPermission(Operation.any, checkHarvestCompleted)) {
      return displayedColumns;
    } else {
      return displayedColumns.filter((column) => column !== 'actions' && column !== 'select');
    }
  }

  public getGlebeDocs() {
    this.db
      .collection(`${this.getHarvestRefDatabase()}/glebes`)
      .valueChanges()
      .subscribe((glebes: Array<any>) => {
        this.glebes = glebes;
      });
  }
  public getTrapsDocs() {
    this.db
      .collection<Trap>(`${this.getFarmRefDatabase()}/traps`)
      .valueChanges()
      .subscribe((traps: Trap[]) => {
        this.traps = traps;
      });
  }
  public getTraps(): Array<Trap> {
    return this.traps;
  }

  public getGlebes(): Array<Glebe> {
    return this.glebes;
  }

  public hasMad(): boolean {
    // true if any glebe has cropId == "FRUTAS"
    return this.glebes.some((glebe) => glebe.cropId === 'FRUTAS');
  }

  // EXEMPLO METODO 2 GET ARGS /////////////////////////
  getTestChart(args: GetWeatherChartArguments) {
    return this.http.get<GetWeatherChartResultDto>(`${environment.api}/chart`, {
      params: args.httpParans,
    });
  }
}
