import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import {
  GET_TRAPS,
  GET_CROPS,
  GET_FIELDS,
  GET_GLEBES,
  GET_GM,
  GET_OCCURRENCES,
  UPDATE_GM,
} from 'src/app/view/gm/gm.actions';
import { ApplicationService } from 'src/app/services/application.service';
import { TranslateTypes } from 'src/app/services/translation.service';
import { MapsGeneralMonitoringComponent } from '../components/maps/maps-general-monitoring/maps-general-monitoring.component';
import { GmTabsComponent } from '../gm-tabs/gm-tabs.component';
import { GmFilterComponent } from './gm-filter/gm-filter.component';
import { GmState, gmStateDefault, rangeDate } from './gm.state';
import { TranslateService } from '@ngx-translate/core';
import { WeatherForecastComponent } from '../components/weather-forecast/weather-forecast.component';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { FieldMonitoring, MapMonitoring, PointMonitoring } from 'src/app/interfaces/Monitoring';

import moment from 'moment';
import _ from 'lodash';
import { Period } from '@tarvos-ag/tarvos-firestore-models/src/enums';
import { Field, Occurrence } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

@Component({
  selector: 'app-gm',
  templateUrl: './gm.component.html',
  styleUrls: ['./gm.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
  ],
})
export class GmComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('mapsGeneralMonitoring', { static: false })
  public mapsGeneralMonitoring!: MapsGeneralMonitoringComponent;
  @ViewChild('appGmTabs', { static: false })
  public appGmTabs!: GmTabsComponent;
  @ViewChild('drawer', { static: false }) public drawer!: MatDrawer;
  @ViewChild('weatherForecast', { static: false })
  public weatherForecast!: WeatherForecastComponent;
  @Input() public picker!: MatDateRangePicker<any>;

  public gmState$: Observable<GmState>;
  public state: GmState = gmStateDefault;
  public cols!: Observable<number | undefined>;
  public mobileQuery: MediaQueryList;
  public laptopQuery: MediaQueryList;
  public mapMonitoring!: Array<MapMonitoring>;
  public mapMonitoringItem: MapMonitoring | null = null;
  public hasHeatMapLegend: any = false;
  public subscribe!: Subscription;
  public subscribeToken!: Subscription;
  public subscribeDataSharing!: Subscription;
  public outerForm: FormGroup;
  public period: any = Period;
  public moment: any = moment;
  public rangeDate!: FormGroup;
  public maxDate: Date = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);

  constructor(
    private store: Store<any>,
    private fireAuth: AngularFireAuth,
    private dialog: MatDialog,
    private media: MediaMatcher,
    private formBuilder: FormBuilder,
    private router: Router,
    public applicationService: ApplicationService,
    private translateService: TranslateService,
    public trans: TranslateTypes
  ) {
    this.mobileQuery = this.media.matchMedia('(max-width: 599px)');
    this.laptopQuery = this.media.matchMedia('(max-width: 1024px)');

    this.gmState$ = this.store.pipe(select('gm'));
    this.outerForm = this.formBuilder.group({
      firstFormGroup: this.formBuilder.group({
        pidNumber: new FormControl(''),
      }),
      secondFormGroup: this.formBuilder.group({
        endpoints: new FormControl(''),
      }),
    });
  }

  /**
   * Este método é executado quando o componente e carregado e pega a intrância do mapa
   */
  public ngOnInit(): void {
    this.initializeForm();
    this.setupStateSubscription();
  }

  private initializeForm(): void {
    this.rangeDate = this.formBuilder.group(_.cloneDeep(rangeDate));
  }

  private setupStateSubscription(): void {
    this.subscribe = this.gmState$.subscribe((state: GmState) => {
      if (JSON.stringify(this.state) === JSON.stringify(state)) {
        return;
      }
      this.state = state;
      this.handleStateChanges();
    });
  }

  private handleStateChanges(): void {
    if (this.shouldProcessState()) {
      this.updateGmConfig();
      this.processGeneralMapState();
      this.updateMapView();
    }

    this.updateFormValues();
  }

  private shouldProcessState(): boolean {
    return this.state.loading == 0 && this.state.fields?.length > 0;
  }

  private processGeneralMapState(): void {
    if (this.isGeneralMapStateValid()) {
      this.setDefaultConfigValues();
      this.store.dispatch(UPDATE_GM({ gm: this.state.gmConfigEdit }));
      this.state.hasGmConfigInfo = false;
    }
  }

  private isGeneralMapStateValid(): boolean {
    return this.state.hasGmConfigInfo && this.state.occurrences?.length > 0;
  }

  private setDefaultConfigValues(): void {
    if (!this.state.gmConfigEdit.trapOccurrenceId) {
      const trapOccurrence = this.state.occurrences[0];
      this.state.gmConfigEdit.trapOccurrenceId = trapOccurrence?.id ?? null;
    }
  }

  private updateMapView(): void {
    if (this.mapsGeneralMonitoring && !this.mapMonitoringItem) {
      this.mapsGeneralMonitoring.setFitBounds();
      this.generatePolygons();
      this.state.shouldRenderMap = false;
    }
  }

  private updateFormValues(): void {
    this.rangeDate.get('startDate')?.setValue(moment(this.state.gmConfigEdit.startDate.toDate()));
    this.rangeDate.get('endDate')?.setValue(moment(this.state.gmConfigEdit.endDate.toDate()));
  }

  /**
   * Este método é executado depois que o componente é carregado e pega a instância do mapa
   */
  public ngAfterViewInit(): void {
    this.subscribeToken = this.fireAuth.idTokenResult.subscribe((auth) => {
      if (auth && auth.token) {
        this.initComponent();
        this.subscribeToken.unsubscribe();
      }
    });

    this.subscribeDataSharing = this.applicationService.updateComponentData.subscribe((key) => {
      if (key === GmComponent.name) {
        this.initComponent();
      }
    });
  }

  /**
   * Este método inicializa do componente
   */
  private initComponent(): void {
    /* Atualiza o componente previsão do tempo */
    if (this.weatherForecast) {
      this.weatherForecast.initWeatherForecast();
    }

    if (this.mapsGeneralMonitoring) {
      this.mapsGeneralMonitoring.removeHeatmapImage();
    }

    this.mapMonitoring = [];
    this.mapMonitoringItem = null;
    this.hasHeatMapLegend = false;

    this.drawer.close();

    this.store.dispatch(GET_OCCURRENCES());
    this.store.dispatch(GET_CROPS());
    this.store.dispatch(GET_GLEBES());
    this.store.dispatch(GET_FIELDS());
    this.store.dispatch(GET_GM());
    this.store.dispatch(GET_TRAPS());
  }

  /**
   * Este método gera uma lista com os polígonos no mapa
   */
  public generatePolygons(): void {
    let fields = this.state.fields;

    /**
     * Filtro por gleba
     */
    if (this.state.gmConfigEdit.glebeIds && this.state.gmConfigEdit.glebeIds.length > 0) {
      fields = fields.filter(
        (field: Field) =>
          this.state.gmConfigEdit.glebeIds.filter((id) => id === field.glebe.id).length > 0
      );
    }

    /**
     * Filtro por talhão
     */
    if (this.state.gmConfigEdit.fieldIds && this.state.gmConfigEdit.fieldIds.length > 0) {
      fields = fields.filter(
        (field: Field) =>
          this.state.gmConfigEdit.fieldIds.filter((id) => id === field.id).length > 0
      );
    }

    if (fields.length > 0) {
      setTimeout(() => {
        const mapMonitoring: Array<MapMonitoring> = [];
        fields.forEach((field: Field) => {
          const data = {
            field,
            fieldMonitoring: {} as FieldMonitoring,
            pointMonitoring: [] as Array<PointMonitoring>,
          } as MapMonitoring;

          mapMonitoring.push(data);
        });

        this.mapMonitoring = mapMonitoring;

        if (this.mapsGeneralMonitoring) {
          this.mapsGeneralMonitoring.getPolygonData(mapMonitoring);
        }
      }, 250);
    } else {
      this.mapsGeneralMonitoring.removeHeatmapImage();
      this.mapMonitoring = [];
    }
  }

  /**
   * Este método é executado quando o componente e destruído
   */
  public ngOnDestroy(): void {
    this.state.loading = 0; // Remover quando o redux for separado por lista e formulário
    this.subscribeToken.unsubscribe();
    this.subscribe.unsubscribe();
    this.subscribeDataSharing.unsubscribe();
  }

  /**
   * Atualiza gmConfig quando trapOcurrenceId não existir em filters
   */
  public updateGmConfig(): void {
    if (!this.checkIfExistsOccurrence()) {
      this.store.dispatch(
        UPDATE_GM({
          gm: { ...this.state.gmConfigEdit, trapOccurrenceId: this.state.occurrences[0].id },
        })
      );
    }
  }

  /**
   * Verifica se existe ocorrêcia em filtros compatível com a ocorrÊncia selecionada em gmConfig
   */
  public checkIfExistsOccurrence(): boolean {
    return this.state.occurrences.some((occurrence) => {
      return occurrence.id === this.state.gmConfigEdit.trapOccurrenceId;
    });
  }

  /**
   * Este método seleciona o período
   */
  public menuPeriodSelectionChange(): void {
    const startDate = this.rangeDate.get('startDate')?.value;
    const endDate = this.rangeDate.get('endDate')?.value;

    if (startDate && endDate) {
      this.state.gmConfigEdit.startDate = startDate;
      this.state.gmConfigEdit.endDate = endDate;
      this.state.gmConfig.startDate = startDate;
      this.state.gmConfig.endDate = endDate;
      this.closeDrawer();
      this.store.dispatch(UPDATE_GM({ gm: this.state.gmConfigEdit }));
    }
  }

  /**
   * Este método abre a modal para aplicar filtro
   */
  public openDialog(): void {
    const dialog = this.dialog.open(GmFilterComponent, {
      disableClose: true,
      panelClass: ['material-dialog-panel', 'material-dialog-panel-800'],
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        if (this.state.fields && this.state.fields.length > 0) {
          this.mapMonitoringItem = null;
          this.drawer.close();
        }
      }
    });
  }

  /**
   * Este método recebe um evento do componente para ser fechado
   */
  public closeDrawer(): any {
    this.mapMonitoringItem = null;
    this.drawer.close();
  }

  /**
   * Este método retorna no número de filtros aplicados
   */
  public filterNumberApplied(): number {
    let value = 0;
    if (this.state.gmConfig) {
      if (this.state.gmConfig.glebeIds && this.state.gmConfig.glebeIds.length > 0) {
        value++;
      }

      if (this.state.gmConfig.fieldIds && this.state.gmConfig.fieldIds.length > 0) {
        value++;
      }

      if (this.state.gmConfig.hideCollectionPoint) {
        value++;
      }

      if (this.state.gmConfig.showFieldWithHighControlLevel) {
        value++;
      }
    }

    return value;
  }

  /**
   * Este método recebe o talhão que foi selecionado
   * @param field Talhão
   */
  public openDrawer(mapMonitoring: MapMonitoring): void {
    this.mapMonitoringItem = mapMonitoring;
    this.drawer.open();
  }

  /**
   * Este método retorna o nome da armadilha com estágio quado tiver
   */
  public getOccurrenceName(): string | null {
    if (this.state.occurrences && this.state.occurrences.length > 0) {
      const occurrenceId = this.state.gmConfigEdit.trapOccurrenceId;
      const lastOccurrence = this.state.occurrences.filter(
        (x: Occurrence) => x.id === occurrenceId
      )[0];

      let occurrenceName;
      if (lastOccurrence) {
        occurrenceName = lastOccurrence.name;
      } else {
        occurrenceName = this.state.occurrences[0].name;
      }

      return `${occurrenceName} `;
    }
    return null;
  }

  /**
   * Este método seta os valores selecionados no menu de ocorrências
   * @param occurrenceId id da ocorrência
   */
  public menuOccurrenceSelectionChange(occurrenceId: string): void {
    this.state.gmConfigEdit.trapOccurrenceId = occurrenceId;
    this.store.dispatch(UPDATE_GM({ gm: this.state.gmConfigEdit }));
    this.closeDrawer();
  }

  /**
   * Este método retorna o id da ocorrência selecionada
   */
  public getOccurrenceSelectedId(): string {
    return this.state.gmConfigEdit.trapOccurrenceId;
  }

  /**
   * Este método retorna o tipo da ocorrência selecionada
   */
  public getOccurrenceSelectedType(): any {
    const occurrenceSelectedId = this.getOccurrenceSelectedId();

    if (occurrenceSelectedId) {
      return this.state.occurrences.filter(
        (occurrence) => occurrence.id === occurrenceSelectedId
      )[0]?.type;
    }
  }

  /**
   * Este método redireciona para o componente desejado e abre a modal de cadastro do mesmo
   * @param componentName nome do componente a ser atualizado
   * @param url caminho para para o componente
   */
  public redirectAnotherPage(componentName: string, url: string): void {
    this.router.navigateByUrl(url).then((value: boolean) => {
      if (value) {
        this.applicationService.updateComponentData.next(componentName);
        this.applicationService.updateComponentData.next(null);
      }
    });
  }

  /**
   * Este método retorna o range de datas
   */
  getRangeDateToString(): string {
    const dateFormat = this.trans.text.date;
    const startDate = this.state.gmConfig.startDate.toDate();
    const endDate = this.state.gmConfig.endDate.toDate();

    if (startDate && endDate) {
      const sDateString = `${moment(startDate).format(this.translateService.instant(dateFormat))}`;
      const eDateString = `${moment(endDate).format(this.translateService.instant(dateFormat))}`;

      if (sDateString == eDateString) return sDateString;
      else return `${sDateString} - ${eDateString}`;
    }

    return '';
  }
}
