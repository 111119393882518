<!-- Loading -->
<app-loading
  [isOpen]="state.loading || state.loadingEvolutionChartByFields"
  [excitedTime]="'1s'"
></app-loading>
<!-- (window:resize)="onResize()" -->
<div class="page-report">
  <header>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 toolbar-container">
          <mat-toolbar class="mat-toolbar">
            <mat-toolbar-row
              class="material-toolbar"
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <div class="content-title-toolbar">
                <a class="icon" href="javascript:;" (click)="openModalFarmDetails()">
                  <img src="./assets/icons/report/ico-fazenda.svg" alt="relatório interativo" />
                </a>
                <div class="infos">
                  <h2 class="title d-desk">
                    {{ trans.title.interactiveReport | translate }}{{ createReportTitle() }}
                  </h2>
                  <h2 class="title d-mobile">
                    {{ trans.title.interactiveReport | translate }}{{ createReportTitle() }}
                  </h2>
                  <span class="date">{{ state.startDate }} - {{ state.endDate }}</span>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="end">
                <ng-container *ngIf="applicationService.getCustomer()?.logo; else elseTemplate">
                  <img
                    class="logo"
                    [src]="'data:image/png;base64,' + applicationService.getCustomer()?.logo"
                    alt="logo"
                  />
                </ng-container>
                <ng-template #elseTemplate>
                  <img
                    class="logo"
                    src="./assets/icons/report/logo-tarvos-vertical.svg"
                    alt="logo"
                  />
                </ng-template>
              </div>
            </mat-toolbar-row>
          </mat-toolbar>
          <!-- <div class="content-caption">
            <div fxLayout="column">
              <div class="caption-title" fxFlex>{{ trans.title.captions | translate }}</div>
              <div class="caption-colum-2" fxLayout="row" fxFlex>
                <div class="caption-pheromone" fxLayout="column" fxLayoutGap="5px" fxFlex>
                  <div class="pheromone-title">{{ trans.title.pheromoneCaption | translate }}</div>
                  <div
                    class="occurrence-caption"
                    fxLayout="row"
                    fxLayoutAlign="start start"
                    *ngFor="let occurrence of getOccurrences()"
                  >
                    <img
                      [src]="getIconPheromoneSvg(occurrence.name).url"
                      style="height: 16px; width: 16px"
                    />
                    <div class="text-pheromone">{{ occurrence.name }}</div>
                  </div>
                  <div
                    class="occurrence-caption"
                    fxLayout="row"
                    fxLayoutAlign="start start"
                    *ngIf="getOccurrences().length > 0"
                  >
                    <img
                      [src]="getIconPheromoneSvg('NO_COMMUNICATION').url"
                      style="height: 16px; width: 16px"
                    />
                    <div class="text-pheromone">{{ trans.label.trapNoCommunication | translate }}</div>
                  </div>
                </div>
                <div fxLayout="column" fxLayoutGap="10px" fxFlex>
                  <div class="heatmap-title" fxFlex>{{ trans.title.heatMap | translate }}</div>
                  <div fxLayout="row">
                    <div class="heatmap">
                      <div class="heatmap-red"></div>
                      <div class="heatmap-yellow"></div>
                      <div class="heatmap-green"></div>
                      <div class="heatmap-white"></div>
                    </div>
                    <div class="caption-infestation" fxLayout="column" fxLayoutGap="3px">
                      <div class="text-pheromone">{{ trans.label.highInfestation | translate }}</div>
                      <div class="text-pheromone">{{ trans.label.mediumInfestation | translate }}</div>
                      <div class="text-pheromone">{{ trans.label.lowInfestation | translate }}</div>
                      <div class="text-pheromone">{{ trans.label.noMonitoring | translate }}</div>
                    </div>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutGap="10%" fxFlex>
                  <div style="background: white; width: 1px"></div>
                  <div fxFlex="53" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="7px">
                    <button class="button-caption" (click)="openLinkWhats()">
                      <div class="whats-icon"></div>
                      <div class="label">CONTATO</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <!-- <button fxFlex class="collapsible" (click)="openCaption($event)">
            <i
              class="las la-angle-down"
              *ngIf="!this.stateCaption"
              style="font-size: 16px; color: white"
            ></i>
            <i
              class="las la-angle-up"
              *ngIf="this.stateCaption"
              style="font-size: 16px; color: white"
            ></i>
          </button> -->
        </div>
      </div>
    </div>
  </header>

  <div class="container-fluid h-100">
    <div class="row h-100">
      <div class="col-lg-6 col-md-12 order-lg-1 order-2 charts-infos">
        <div class="charts-infos-container">
          <!-- RETIRADO: !this.noOccurrence && -->
          <div class="tabs" [style.display]="!state.loading ? 'block' : 'none'">
            <button
              class="farm tablinks"
              (click)="tabSelected($event)"
              id="defaultOpen"
              [ngClass]="{ active: !glebeSelected }"
            >
              <span class="content-field">Todas</span>
            </button>
            <button
              class="field tablinks"
              [id]="obj.glebe.id"
              *ngFor="let obj of fieldsByGlebe"
              (click)="tabSelected($event, obj)"
            >
              <span class="content-field" *ngIf="obj.glebe.alias">
                {{ obj.glebe.alias }}
              </span>
              <span class="content-field" *ngIf="!obj.glebe.alias">
                {{ obj.glebe.name }}
              </span>
            </button>
          </div>

          <div class="content-infos">
            <!-- <div *ngIf="!this.noOccurrence">
              Para monitorar a ocorrência específica de um atrativo, clique no botão da ocorrência
              desejada.
            </div> -->

            <!-- MÉDIA MAD CHART -->
            <div
              class="mat-elevation-z8 card-chart chart-media-mad"
              *ngIf="!this.noOccurrence && !glebeSelected && reportHasMad()"
            >
              <div class="chart-title">
                <mat-card-title>
                  <h4>{{ trans.title.ftdMean | translate }}</h4>
                </mat-card-title>

                <!-- Legend -->
                <div class="legend">
                  <ul class="legend-mad">
                    <li>
                      <span class="green"></span>
                      < {{ getMadControlLevels().control }}
                    </li>
                    <li>
                      <span class="yellow"></span>
                      {{ getMadControlLevels().control }} a {{ getMadControlLevels().damage }}
                    </li>
                    <li>
                      <span class="red"></span>
                      > {{ getMadControlLevels().damage }}
                    </li>
                  </ul>
                </div>
              </div>

              <mat-card-content class="card-content-chart">
                <ng-container>
                  <app-media-mad-chart [data]="this.getMediaMadChart()"></app-media-mad-chart>
                </ng-container>
              </mat-card-content>
            </div>

            <!-- // BOX MÉDIA MAD ////////////// -->
            <div class="average-mad" *ngIf="glebeSelected && reportHasMad(glebeSelected)">
              <div class="average {{ getFtdLevel(glebeSelectedInfos!.ftdMean) }}">
                <p>
                  {{ trans.title.ftdMean | translate }}
                  <!-- TODO: Check array position  -->
                  <span class="result">{{ glebeSelectedInfos!.ftdMean.toFixed(2) }}</span>
                </p>
              </div>
              <div class="legend d-desk">
                <ul class="legend-mad">
                  <li>
                    <span class="green"></span>
                    < {{ getMadControlLevels().control }}
                  </li>
                  <li>
                    <span class="yellow"></span>
                    {{ getMadControlLevels().control }} a {{ getMadControlLevels().damage }}
                  </li>
                  <li>
                    <span class="red"></span>
                    > {{ getMadControlLevels().damage }}
                  </li>
                </ul>
              </div>
            </div>

            <!-- Evolution chart LINE -->
            <div class="mat-elevation-z8 card-chart">
              <div class="chart-title">
                <mat-card-title>
                  <h4>{{ trans.title.dailyCaptures | translate }}</h4>
                  <span>{{ titleChart }}</span>
                </mat-card-title>

                <!-- Filtro de ocorrências -->
                <div class="occurrence-filter-evolution">
                  <button
                    class="button-occurrence filterlinks"
                    fxFlex
                    *ngFor="let occurrence of getOccurrences()"
                    (click)="onSelectionChangeOccurrence(occurrence)"
                    [ngClass]="{ actived: occurrence.id === occurrenceSelected.id }"
                  >
                    <img class="icon" [src]="getIconOccurrence(occurrence.id)" />
                    <div
                      class="content"
                      [ngClass]="{ actived: occurrence.id === occurrenceSelected.id }"
                    >
                      {{ occurrence.name }}
                    </div>
                  </button>
                </div>
              </div>
              <mat-card-content class="card-content-chart">
                <ng-container *ngIf="getEvolutionChartData()">
                  <app-evolution-chart
                    [data]="getEvolutionChartData()"
                    [isFruit]="true"
                  ></app-evolution-chart>
                </ng-container>
              </mat-card-content>
            </div>

            <!-- // OUTRAS INFORMAÇÕES ////////////// -->
            <div class="other-informations" *ngIf="glebeSelected">
              <hr />

              <h4>{{ trans.title.otherInfos | translate }}</h4>

              <div class="box box-farm">
                <h5>{{ glebeSelected.alias }}</h5>
                <div class="farm-details">
                  Cultura: {{ glebeSelected.cropName }}<br />
                  Variedade: {{ glebeSelected.varieties }}<br />
                  Área (ha): {{ glebeSelectedInfos?.area?.toFixed(2) }}<br />
                  Total talhões: {{ glebeSelectedInfos?.nFields }}<br />
                  Total armadilhas: {{ glebeSelectedInfos?.nTraps }}<br />
                  MAD: <span class="marker {{ getFtdLevel(glebeSelectedInfos?.ftdMean) }}"></span>
                  {{ glebeSelectedInfos?.ftdMean?.toFixed(2) }}<br />
                </div>
              </div>

              <div class="box box-fields">
                <h5 *ngIf="!fieldSelected">{{ trans.title.fields | translate }}</h5>
                <ul class="fields">
                  <li *ngFor="let field of getFieldsInfos()">
                    <h6>{{ field.name }}</h6>
                    Área (ha): {{ field.area.toFixed(2) }}<br />
                    Total armadilhas: {{ field.nTraps }}<br />
                    <span *ngIf="getFieldInfestation(field.id)">
                      Infestação: <span class="marker {{ getFieldInfestation(field.id) }}"></span>
                      {{ trans.label[getFieldInfestation(field.id)!.toLowerCase()] | translate
                      }}<br />
                    </span>
                    <ul class="traps" *ngIf="getTrapsModels(glebeSelected.id, field.id).length > 0">
                      <h6>{{ trans.title.traps | translate }}</h6>
                      <li *ngFor="let model of getTrapsModels(glebeSelected.id, field.id)">
                        {{ model.model }}: {{ model.count }}
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>

              <!-- ARMADILHAS / FALTANDO INFORMAÇÕES -->
              <div class="box box-trap" *ngIf="fieldSelected">
                <h5>{{ trans.label.traps | translate }}</h5>
                <ul class="traps">
                  <li *ngFor="let trap of getTrapsFromField(fieldSelected.id)">
                    {{ formatTrapName(trap) }}
                  </li>
                </ul>

                <p>
                  As armadilhas contém atrativo de:
                  <strong>Ceratitis capitata</strong>
                </p>

                <div class="info-icon">
                  <img src="./assets/icons/pheromone_type_4.svg" />
                  <span
                    >Esse é o ícone que representa armadilhas com atrativo para Ceratitis capitata
                    no mapa.</span
                  >
                </div>
              </div>
            </div>

            <!-- WEATHER CHART -->
            <div
              class="mat-elevation-z8 card-chart chart-media-mad"
              *ngIf="this.glebeSelected && this.weatherData.length > 0"
            >
              <div class="chart-title">
                <mat-card-title>
                  <h4>{{ trans.title.weatherData | translate }}</h4>
                  <span>Temperatura (ºC) e Precipitação (mm)</span>
                </mat-card-title>
              </div>
              <mat-card-content class="card-content-chart">
                <ng-container>
                  <app-weather-chart [data]="weatherData"></app-weather-chart>
                </ng-container>
              </mat-card-content>
            </div>

            <!-- Card Farm/Fields info 
            <div class="cards-farm-infos">
              <div class="mat-elevation-z8 card" *ngIf="cardFarm && !this.noOccurrence">
                <div style="padding: 15px">
                  <mat-card-title>{{ cardFarm.title }}</mat-card-title>
                </div>
                <mat-card-content class="card-container">
                  <div class="card-container" fxLayout="row">
                    <div fxFlex="50" fxLayout="row" fxLayoutAlign="center center">
                      <img
                        style="height: 80px; width: 80px; text-align: center"
                        [src]="cardFarm.image"
                      />
                    </div>
                    <div
                      fxFlex="50"
                      fxLayout="column"
                      fxLayoutAlign="center start"
                      fxLayoutGap="5px"
                    >
                      <div class="title-field">{{ cardFarm.subTitle }}</div>
                      <div class="card-line" *ngFor="let line of cardFarm.field">
                        <span class="card-label">{{ line.label }}:</span
                        ><span class="card-content">{{ line.content }}</span>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </div>

              <div *ngIf="!this.noOccurrence">
                <div>
                  <div class="mat-elevation-z8 card" *ngFor="let trap of cardTrap">
                    <div style="padding: 15px">
                      <mat-card-title>{{ trap.title }}</mat-card-title>
                    </div>
                    <mat-card-content class="card-container">
                      <div class="card-container" fxLayout="row">
                        <div fxFlex="40" fxLayout="row" fxLayoutAlign="center center">
                          <img
                            style="height: 80px; width: 80px; text-align: center"
                            [src]="trap.image"
                          />
                        </div>
                        <div
                          fxFlex="60"
                          fxLayout="column"
                          fxLayoutAlign="center start"
                          fxLayoutGap="5px"
                        >
                          <div class="title-field">{{ trap.subTitle }}</div>
                          <div class="card-line">
                            <span class="card-trap-text">{{ trap.pheromoneText }} </span>
                            <span class="card-trap-text bold">{{ trap.pheromone }}</span>
                          </div>
                          <div fxLayout="row">
                            <div fxFlex="30" fxLayout="row" fxLayoutAlign="center center">
                              <img
                                style="height: 24px; width: 24px; text-align: center"
                                [src]="trap.icon"
                              />
                            </div>
                            <div fxFlex="70" class="card-trap-pheromone">{{ trap.iconText }}</div>
                          </div>
                        </div>
                      </div>
                    </mat-card-content>
                  </div>
                </div>
              </div>
            </div>
            -->

            <!-- <pre>
              fields:<br> {{ this.glebeSelectedInfos?.fields | json }}
            </pre> 

            <hr />
            <pre>
              field selecionado:<br> {{ fieldSelected | json }}
            </pre> -->
          </div>
        </div>
      </div>

      <!-- // SEM DADOS OCORRÊNCIA //////////-->
      <div class="col-md-6 order-md-2 order-1 noOccurrence" *ngIf="noOccurrence && !state.loading">
        <div class="content">
          <h2>
            {{ trans.title.noOccurrencesReportFruit | translate }}
          </h2>
          <button mat-raised-button class="button-back-farm" (click)="onResetAll()">
            <img src="./assets/icons/report/ico-home.svg" alt="Home" />
            <span>Voltar</span>
          </button>
        </div>
      </div>

      <!-- // MAP ////////////////////// -->
      <div
        class="col-lg-6 col-md-12 card-Map order-lg-2 order-1"
        *ngIf="initMap && !this.noOccurrence"
      >
        <div class="row h-100">
          <!-- Google maps - heatmap -->
          <agm-map
            fxFlex
            *ngIf="true"
            #AgmMap
            [latitude]="-21.31370884151141"
            [longitude]="-45.93946863345604"
            [zoom]="20"
            [maxZoom]="20"
            [minZoom]="8"
            (zoomChange)="onZoomChange($event)"
            [gestureHandling]="'greedy'"
            [mapTypeId]="mapTypeId"
            [ngStyle]="{ height: expandView || !isMobile ? '90vh' : '40vh' }"
            [scrollwheel]="true"
            [disableDoubleClickZoom]="true"
            [mapDraggable]="true"
            [disableDefaultUI]="true"
            [backgroundColor]="'transparent'"
            (mapReady)="mapReady($event)"
          >
            <!-- CONTORNOS ÁREAS  -->
            <ng-container *ngIf="glebeSelected">
              <div *ngFor="let field of this.state.fields">
                <!-- Polygon ( zIndex 1 ) -->

                <agm-polygon
                  [fillOpacity]="0.1"
                  [zIndex]="1"
                  [fillColor]="'#ffffff'"
                  [strokeWeight]="3"
                  [strokeColor]="
                    monitoringReportSelected?.field?.fieldId === field.id ? '#004762' : '#fafafa'
                  "
                  [geodesic]="true"
                  [paths]="getPolygonPoints(field.id)"
                  (polyClick)="onFieldSelect(field.id, field.glebe.id)"
                >
                </agm-polygon>
              </div>
            </ng-container>

            <!-- FARMS/GLEBES -->
            <div *ngFor="let map of getOcorrence()">
              <ng-container *ngIf="glebeSelected">
                <!-- Marker ( zIndex 3 ) -->
                <agm-marker
                  *ngFor="let point of map.points; let i = index"
                  [latitude]="point.lat"
                  [longitude]="point.lng"
                  [zIndex]="3"
                  [iconUrl]="point.icon"
                >
                  <agm-snazzy-info-window
                    *ngIf="point.name || point.name"
                    [isOpen]="openTagPoint"
                    [closeOnMapClick]="false"
                    [closeWhenOthersOpen]="false"
                    [openOnMarkerClick]="false"
                    [panOnOpen]="false"
                    [showCloseButton]="false"
                    [placement]="'right'"
                    wrapperClass="material-marker-name"
                  >
                    <ng-template>
                      {{ point.name }}
                    </ng-template>
                  </agm-snazzy-info-window>
                </agm-marker>
              </ng-container>

              <!-- MARKER OCORRÊNCIA -->
              <agm-marker
                [latitude]="map.centroid.lat"
                [longitude]="map.centroid.lng"
                [zIndex]="3"
                [iconUrl]="map.icoUrl"
                (markerClick)="tabSelected('', map.glebe)"
                *ngIf="map.centroid"
                (mouseOver)="onMouseOverMarker(infoWindow, AgmMap)"
                (mouseOut)="onMouseOutMarker(AgmMap)"
              >
                <agm-info-window #infoWindow [disableAutoPan]="false">
                  <br />
                  <span *ngIf="map.glebe.glebe.alias">{{ map.glebe.glebe.alias }}</span>
                  <span *ngIf="!map.glebe.glebe.alias">{{ map.glebe.glebe.name }}</span>
                </agm-info-window>
              </agm-marker>
            </div>
          </agm-map>
        </div>
        <div class="navigation-footer-map">
          <div class="legend-container">
            <div class="legend-content-box" *ngIf="showMenuLegend">
              <div class="header-legend">
                <h2>
                  <img src="./assets/icons/report/ico-legenda.svg" />
                  Legendas
                </h2>
                <button type="button" class="btn-close" (click)="onMenuLegendClick()">
                  <i class="las la-times"></i>
                </button>
              </div>

              <ul class="pragas-tipos">
                <h3>Tipo de praga</h3>
                <li>
                  <img src="./assets/images/insect_one.png" />
                  Anastrepha
                </li>
                <li>
                  <img src="./assets/images/insect_two.png" />
                  Ceratitis capitata
                </li>
              </ul>

              <div class="heatmap-container">
                <h3>
                  {{ trans.title.heatMap | translate }}
                </h3>
                <div fxLayout="row">
                  <ul class="heatmap">
                    <li class="item-heatmap heatmap-red"></li>
                    <li class="item-heatmap heatmap-yellow"></li>
                    <li class="item-heatmap heatmap-green"></li>
                    <li class="item-heatmap heatmap-white"></li>
                  </ul>
                  <ul class="caption-infestation">
                    <li class="text-pheromone">{{ trans.label.highInfestation | translate }}</li>
                    <li class="text-pheromone">{{ trans.label.mediumInfestation | translate }}</li>
                    <li class="text-pheromone">{{ trans.label.lowInfestation | translate }}</li>
                    <li class="text-pheromone">{{ trans.label.noMonitoring | translate }}</li>
                  </ul>
                </div>
              </div>

              <ul class="feromonio-tipos">
                <h3>{{ trans.title.pheromone | translate }}</h3>
                <li *ngFor="let occurrence of getOccurrences()">
                  <img [src]="getIconPheromoneSvg(occurrence.name).url" />
                  {{ occurrence.name }}
                </li>
                <li *ngIf="getOccurrences().length > 0">
                  <img [src]="getIconPheromoneSvg('NO_COMMUNICATION').url" />
                  {{ trans.label.trapNoCommunication | translate }}
                </li>
              </ul>
            </div>
            <button mat-raised-button class="legend-button" (click)="onMenuLegendClick()">
              <img src="./assets/icons/report/ico-legenda.svg" alt="Legenda" />
            </button>
          </div>
          <button
            mat-raised-button
            class="button-back-farm"
            (click)="tabSelected($event)"
            [hidden]="!glebeSelected"
          >
            <img src="./assets/icons/report/ico-home.svg" alt="Home" />
          </button>
        </div>
        <!-- <mat-card
          class="card-Map"
          fxLayout="column"
          fxFlex.gt-md="100"
          *ngIf="!this.noOccurrence && !state.loading"
        >
        </mat-card> -->
      </div>
    </div>
  </div>
</div>
