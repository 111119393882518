import { XYChart } from '@amcharts/amcharts4/.internal/charts/types/XYChart';
import { Component, Input, NgZone, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateTypes } from 'src/app/services/translation.service';
import { BaseGraph } from '../../../utils/base-graph/base-graph';
import { LineSeries } from '@amcharts/amcharts4/charts';
import { chartColor } from 'src/app/constants/color';
import { EvolutionChart } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { Data } from '@angular/router';
import { ApplicationService } from 'src/app/services/application.service';
import { UnitOfMeasureOfOccurrence } from '@tarvos-ag/tarvos-firestore-models/src/enums';

@Component({
  selector: 'app-evolution-chart',
  templateUrl: './evolution-chart.component.html',
  styleUrls: ['./evolution-chart.component.scss'],
})
export class EvolutionChartComponent extends BaseGraph implements OnDestroy {
  @Input() public set data(data: EvolutionChart) {
    this.evolutionChart = data;
    this.initChart();
  }

  @Input() public set isFruit(isFruit: boolean) {
    this.isFruitBool = isFruit;
  }

  public isFruitBool: boolean = false;

  public evolutionChart!: EvolutionChart;

  private chart!: XYChart;

  private colors = [
    '#006600',
    '#000066',
    '#999900',
    '#880088',
    '#008888',
    '#888888',
    '#BB2222',
    '#00CC00',
    '#0000CC',
    '#CC6633',
    '#CC00CC',
    '#00CCCC',
    '#CC9933',
    '#CCCCCC',
    '#333333',
    '#339933',
    '#333399',
    '#CCCC33',
    '#AA33AA',
    '#33AAAA',
    '#AAAAAA',
    '#CC6666',
    '#33CC33',
    '#3333CC',
    '#CC9966',
    '#CC66CC',
    '#40DA08',
    '#CCCC66',
    '#F01221',
    '#2222FF',
    '#66CCCC',
    '#FF9999',
    '#661166',
    '#8040DD',
    '#FF6622',
    '#DD22DA',
    '#50A053',
    '#AAAA50',
    '#FA6080',
    '#3672DD',
  ];

  constructor(
    public anotherTranslateService: TranslateService,
    public trans: TranslateTypes,
    public zone: NgZone,
    public applicationService: ApplicationService
  ) {
    super(anotherTranslateService, applicationService);
  }

  public ngOnDestroy(): void {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

  public initChart(): void {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }

      // Criar gráfico
      this.createXYChart('evolutionChart', this.am4charts.XYChart);

      // Criar DateAxis no eixo x
      const dateAxis = this.createBasicXYChartDateAxis();

      // RANGES
      // Create Date Ranges
      function createRangeGrid(am4core: any, dateString: string) {
        const date = new Date(dateString + 'T00:00:00-0300');
        let range = dateAxis.axisRanges.create();
        range.date = date;
        range.grid.strokeOpacity = 0.6;
        range.grid.strokeWidth = 1;
        range.grid.strokeDasharray = '12,12';
        range.grid.fill = am4core.color('#333333');
        range.tick.disabled = false;
        range.tick.strokeOpacity = 0.5;
        range.tick.length = 50;
      }
      function createRangeLabels(am4core: any, dateString: string, dates: Array<string>) {
        const date = new Date(dateString + 'T00:00:00-0300');
        const dayBefore = new Date(date.getTime() - 24 * 60 * 60 * 1000);

        const weekIndex = dates.findIndex((d) => d == dateString);

        const firstDate = new Date(dates[0]);
        firstDate.setTime(firstDate.getTime() + 12 * 60 * 60 * 1000);
        let range1 = dateAxis.axisRanges.create();
        range1.endDate = date;
        range1.date = firstDate;
        const range1Sep = weekIndex > 1 ? '- '.repeat(3 * weekIndex) : '- '.repeat(weekIndex);
        range1.label.text = `${range1Sep} Semana ${getWeekNumber(dayBefore)} ${range1Sep}`;
        range1.label.paddingTop = 30;
        range1.label.location = 0.5;
        range1.label.horizontalCenter = 'middle';
        range1.label.fontWeight = 'lighter';
        range1.label.fontSize = 14;
        // range1.axisFill.fill = am4core.color("#555555");
        // range1.axisFill.fillOpacity = 0.3;
        range1.grid.disabled = true;

        const lastDate = new Date(dates[dates.length - 1]);
        lastDate.setTime(lastDate.getTime() + 24 * 60 * 60 * 1000);
        let range2 = dateAxis.axisRanges.create();
        range2.endDate = lastDate;
        range2.date = date;
        const range2Sep =
          weekIndex < 5 ? '- '.repeat(3 * (6 - weekIndex)) : '- '.repeat(6 - weekIndex);
        range2.label.text = `${range2Sep} Semana ${getWeekNumber(range2.endDate)} ${range2Sep}`;
        range2.label.paddingTop = 30;
        range2.label.location = 0.5;
        range2.label.horizontalCenter = 'middle';
        range2.label.fontWeight = 'lighter';
        range2.label.fontSize = 14;
        range2.grid.disabled = true;
      }
      function getWeekNumber(date: Date): number {
        const startDate = new Date(date.getFullYear(), 0, 1);
        const days = Math.floor((date.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000));
        const weekNumber = Math.ceil((days + startDate.getDay()) / 7);
        return weekNumber;
      }
      if (this.isFruitBool) {
        let dates: Array<string> = [];
        this.evolutionChart.data.forEach((field) => {
          field.forEach((data) => {
            const date = data.date.substring(0, 10);
            if (dates.findIndex((d) => d == date) == -1) dates.push(date);
          });
        });
        dates = dates.sort((a, b) => a.localeCompare(b));
        for (const date of dates) {
          const dateObj = new Date(date + 'T00:00:00-0300');
          if (dateObj.getDay() == 1) {
            // Monday
            createRangeGrid(this.am4core, date);
            createRangeLabels(this.am4core, date, dates);
          }
        }
      }

      // Criar ValueAxis no eixo y
      const valueAxis = this.createBasicXYChartValueAxis();
      valueAxis.maxPrecision = 0;

      if (this.evolutionChart?.unit === UnitOfMeasureOfOccurrence.boolean) {
        valueAxis.min = -2;
        valueAxis.max = 2;
        valueAxis.strictMinMax = true;
        valueAxis.renderer.labels.template.disabled = true;

        const range1 = this.addBasicXYChartAxisRanges(
          valueAxis,
          0,
          2,
          this.trans.label.presence,
          chartColor.red,
          chartColor.red
        );
        const range2 = this.addBasicXYChartAxisRanges(
          valueAxis,
          0,
          -2,
          this.trans.label.absence,
          chartColor.midGreen,
          chartColor.midGreen
        );

        if (this.applicationService.getIsDarkTheme()) {
          range1.axisFill.fillOpacity = 0.1;
          range2.axisFill.fillOpacity = 0.1;
        } else {
          range1.axisFill.fillOpacity = 0.2;
          range2.axisFill.fillOpacity = 0.2;
        }
      } else if (this.evolutionChart?.unit === UnitOfMeasureOfOccurrence.intensity) {
        valueAxis.min = -4;
        valueAxis.max = 4;
        valueAxis.strictMinMax = true;
        valueAxis.renderer.labels.template.disabled = true;

        // ausência  baixa media ala

        const range1 = this.addBasicXYChartAxisRanges(
          valueAxis,
          2,
          4,
          this.trans.label.high,
          chartColor.red,
          chartColor.red
        );
        const range2 = this.addBasicXYChartAxisRanges(
          valueAxis,
          0,
          2,
          this.trans.label.average,
          chartColor.yellow,
          chartColor.yellow
        );
        const range3 = this.addBasicXYChartAxisRanges(
          valueAxis,
          0,
          -2,
          this.trans.label.low,
          chartColor.midGreen,
          chartColor.midGreen
        );
        const range4 = this.addBasicXYChartAxisRanges(
          valueAxis,
          -2,
          -4,
          this.trans.label.absence,
          chartColor.lightGrey,
          chartColor.lightGrey
        );

        if (this.applicationService.getIsDarkTheme()) {
          range1.axisFill.fillOpacity = 0.1;
          range2.axisFill.fillOpacity = 0.1;
          range3.axisFill.fillOpacity = 0.1;
          range4.axisFill.fillOpacity = 0.1;
        } else {
          range1.axisFill.fillOpacity = 0.2;
          range2.axisFill.fillOpacity = 0.2;
          range3.axisFill.fillOpacity = 0.2;
          range4.axisFill.fillOpacity = 0.2;
        }
      } else {
        valueAxis.min = 0;
        valueAxis.renderer.minGridDistance = 20;
        valueAxis.renderer.labels.template.adapter.add('text', (text) => text);
      }

      // Habilitar scrollbar
      // this.addBasicXYChartScrollbarX();

      // Adicionar legenda
      this.addBasicXYChartLegend();
      // var chart = this.am4core.create("chartdiv", this.am4charts.PieChart);
      // var pieSeries = chart.series.push(new this.am4charts.PieSeries());
      // pieSeries.labels.template.disabled = true;
      // //lineseries.labels.template.disabled = true;
      // chart.events.on("ready", function(event) {
      //   // populate our custom legend when chart renders
      //   chart.customLegend = document.getElementById('legend');
      //   pieSeries.dataItems.each(function(row, i) {
      //     var color = chart.colors.getIndex(i);
      //     var percent = Math.round(row.values.value.percent * 100) / 100;
      //     var value = row.value;
      //     legend.innerHTML += '<div class="legend-item" id="legend-item-' + i + '" onclick="toggleSlice(' + i + ');" onmouseover="hoverSlice(' + i + ');" onmouseout="blurSlice(' + i + ');" style="color: ' + color + ';"><div class="legend-marker" style="background: ' + color + '"></div>' + row.category + '<div class="legend-value">' + value + ' | ' + percent + '%</div></div>';
      //   });
      // });

      if (this.evolutionChart) {
        // Nível de controle '#e91e63'
        if (this.evolutionChart.controlLevels?.length > 0) {
          this.evolutionChart.controlLevels[0].name = 'CONTROLE';
          this.evolutionChart.controlLevels[1].name = 'CONTROLE';

          const lineSeries = this.createTrendLine(
            this.evolutionChart.controlLevels,
            false,
            true,
            '#e91e63',
            null
          );
          lineSeries.strokeDasharray = '3,3';
        }

        // Total #000000
        if (this.evolutionChart.accumulated.length > 0) {
          const total = this.evolutionChart.accumulated.map((i) => ({ ...i, name: 'TOTAL' }));
          this.createTrendLine(total, false, false, '#000000', 0.1, true);
        }

        // Adiciona pontos ---- 0 -> ausência, 0.1 -> baixo, 0.5 -> médio, 1 -> auto
        if (this.evolutionChart.data?.length > 0) {
          const data: any = this.evolutionChart.data
            .sort((a, b) => a[0].name.localeCompare(b[0].name))
            .map((element: any) => {
              if (this.evolutionChart?.unit === UnitOfMeasureOfOccurrence.intensity) {
                element.map((element1: Data) => {
                  element1.value =
                    element1.value === 0
                      ? -3
                      : element1.value === 0.1
                      ? -1
                      : element1.value === 0.5
                      ? 1
                      : element1.value === 1
                      ? 3
                      : element1.value;
                });
              } else if (this.evolutionChart?.unit === UnitOfMeasureOfOccurrence.boolean) {
                element.map((element1: Data) => {
                  element1.value =
                    element1.value === 1 ? 1 : element1.value === 0 ? -1 : element1.value;
                });
              }

              return element;
            });

          for (let i = 0; i < data.length; i++)
            this.createTrendLine(data[i], false, true, this.colors[i], null);
          if (data.length > this.colors.length) {
            for (let i = this.colors.length; i < data.length; i++)
              this.createTrendLine(data[i], true, true, null, null);
          }
        }
      }

      this.chart = this.xYChart;
    });
  }

  /**
   * Este método gera as linhas do gráfico
   * @param data conteúdo do gráfico
   * @param randomColor gerar cor automático
   */
  public createTrendLine(
    data: any,
    randomColor: boolean,
    hasCircleBullet: boolean,
    color: string | null,
    fillOpacity: number | null,
    hidden: boolean = false
  ): LineSeries {
    const trend = this.xYChart.series.push(new this.am4charts.LineSeries());

    if (
      this.evolutionChart?.unit === UnitOfMeasureOfOccurrence.boolean ||
      this.evolutionChart?.unit === UnitOfMeasureOfOccurrence.intensity
    ) {
      trend.tooltipText = `[bold font-size: 13px]{name}`;
    } else {
      trend.tooltipText = `[bold font-size: 13px]{name}: {valueY}`;
    }

    trend.dataFields.valueY = 'value';
    trend.dataFields.dateX = 'date';
    trend.name = data[0].name;
    trend.strokeWidth = 2;
    trend.fillOpacity = fillOpacity != null ? fillOpacity : 0;
    trend.propertyFields.strokeOpacity = 'notHadStickyExchange';

    if (!randomColor) {
      if (color) {
        trend.stroke = trend.fill = this.am4core.color(color.toString());
      } else if (this.xYChart.series.values.length > 1) {
        const lastColor =
          this.xYChart.series.values[this.xYChart.series.values.length - 1].stroke.toString();
        trend.stroke = trend.fill = this.am4core.color(lastColor);
      }
    }

    if (hasCircleBullet) {
      const bullet = trend.bullets.push(new this.am4charts.CircleBullet());
      bullet.strokeWidth = 0.5;
      // bullet.stroke = this.am4core.color('#fff');
      bullet.circle.fill = trend.stroke;

      const hoverState = bullet.states.create('hover');
      hoverState.properties.scale = 1.7;
    }

    trend.data = data;

    trend.tooltip?.adapter.add('tooltipText', (text) => {
      return text + '1';
    });

    // Evento
    trend.adapter.add('tooltipText', (text, target) => {
      const value: any = target.tooltipDataItem.dataContext;

      if (this.evolutionChart && value?.notHadStickyExchange === 0) {
        return `[bold font-size: 13px]${this.translateService.instant(
          this.trans.text.stickyExchange
        )}\n${text}`;
      } else {
        const fillFactor = value?.fillFactor
          ? `[bold font-size: 13px] ${this.translateService.instant(
              this.trans.label.fillFactor
            )}: ${this.translateService.instant(
              this.trans.text[value?.fillFactor.toLowerCase()]
            )} \n `
          : ``;
        return `${fillFactor} ${text}`;
      }
    });
    trend.hidden = hidden;
    return trend;
  }
}
