import { Action, createReducer, on } from '@ngrx/store';
import { ReportFruitState, reportFruitStateDefault } from './report-fruit.state';

import * as _ from 'lodash';
import * as reportFruitAction from './report-fruit.actions';

export const reportReducer = createReducer(
  _.cloneDeep(reportFruitStateDefault),

  /**
   * GET
   */

  on(reportFruitAction.GET_REPORT_FRUIT, (state) => ({ ...state, loading: state.loading + 1 })),
  on(reportFruitAction.GET_REPORT_FRUIT_SUCCESS, (state, action) => ({
    ...state,
    customerId: action.customerId,
    farmId: action.farmId,
    farm: action.farm,
    harvestId: action.harvestId,
    fields: action.fields,
    occurrences: action.occurrences,
    reportFruit: action.reportFruit,
    startDate: action.data.startDate,
    endDate: action.data.endDate,
    showModal: action.showModal,
    evolutionChart: action.data.evolutionChart,
    madControlLevels: action.data.madControlLevels,
    monitoringReport: action.data.monitoringReport,
    infos: action.data.infos,
    weatherChartFields: action.farm.weather,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(reportFruitAction.GET_REPORT_FRUIT_FAIL, (state) => ({
    ...state,
    fields: [],
    occurrences: [],
    reportFruit: null,
    monitoringReport: [],
    evolutionChart: [],
    infos: [],
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  on(reportFruitAction.GET_EVOLUTION_CHART, (state) => ({
    ...state,
    loadingEvolutionChartByFields: state.loadingEvolutionChartByFields + 1,
  })),
  on(reportFruitAction.GET_EVOLUTION_CHART_SUCCESS, (state, action) => ({
    ...state,
    evolutionChartByFields: action.evolutionChart,
    loadingEvolutionChartByFields: (state.loadingEvolutionChartByFields = 0),
  })),
  on(reportFruitAction.GET_EVOLUTION_CHART_FAIL, (state) => ({
    ...state,
    evolutionChartByFields: null,
    loadingEvolutionChartByFields: (state.loadingEvolutionChartByFields = 0),
  })),

  /*
  on(reportFruitAction.GET_WEATHER_CHART, (state) => ({
    ...state,
    loadingWeatherChartByFields: state.loadingWeatherChartByFields + 1,
  })),
  on(reportFruitAction.GET_WEATHER_CHART_SUCCESS, (state, action) => ({
    ...state,
    weatherChartFields: action.weatherChart,
    loadingWeatherChartByFields: (state.loadingWeatherChartByFields = 0),
  })),
  on(reportFruitAction.GET_WEATHER_CHART_FAIL, (state) => ({
    ...state,
    weatherChartFields: null,
    loadingWeatherChartByFields: (state.loadingWeatherChartByFields = 0),
  })),
  */

  // on(reportFruitAction.GET_ACCUMULATED_CHART, (state) => ({
  //   ...state,
  //   loading: state.loadingAccumulatedChart + 1,
  // })),
  // on(reportFruitAction.GET_ACCUMULATED_CHART_SUCCESS, (state, action) => ({
  //   ...state,
  //   reportAccumulated: action.reportAccumulated,
  //   loading: (state.loadingAccumulatedChart = 0),
  // })),
  // on(reportFruitAction.GET_ACCUMULATED_CHART_FAIL, (state) => ({
  //   ...state,
  //   reportAccumulated: null,
  //   loading: (state.loadingAccumulatedChart = 0),
  // })),

  // on(reportFruitAction.GET_MAD_CHART, (state) => ({
  //   ...state,
  //   loading: state.loadingMadChart + 1,
  // })),
  // on(reportFruitAction.GET_MAD_CHART_SUCCESS, (state, action) => ({
  //   ...state,
  //   madChart: action.madChart,
  //   loading: (state.loadingMadChart = 0),
  // })),
  // on(reportFruitAction.GET_MAD_CHART_FAIL, (state) => ({
  //   ...state,
  //   madChart: [],
  //   loading: (state.loadingMadChart = 0),
  // })),

  on(reportFruitAction.GET_PHONE, (state) => ({
    ...state,
  })),
  on(reportFruitAction.GET_PHONE_SUCCESS, (state, action) => ({
    ...state,
    phone: action.phone,
  })),
  on(reportFruitAction.GET_PHONE_FAIL, (state) => ({
    ...state,
    phone: '',
  })),

  /**
   * OTHER
   */
  on(reportFruitAction.DESTROY_COMPONENT, () => ({
    ..._.cloneDeep(reportFruitStateDefault),
  }))
);

export function reducer(state: ReportFruitState | undefined, action: Action): ReportFruitState {
  return reportReducer(state, action);
}
