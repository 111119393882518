import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import { AfterViewInit, Component, Input, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateTypes } from 'src/app/services/translation.service';
import { ApplicationService } from 'src/app/services/application.service';
import { XYChart } from '@amcharts/amcharts4/charts';
import am4themes_material from '@amcharts/amcharts4/themes/material';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { WeatherChart } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/Weather';
import { BaseGraph } from 'src/app/view/utils/base-graph/base-graph';

am4core.useTheme(am4themes_material);
am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-weather-chart',
  templateUrl: './weather-chart.component.html',
  styleUrls: ['./weather-chart.component.scss'],
})
export class WeatherChartComponent extends BaseGraph implements AfterViewInit {
  @Input() public set data(data: Array<WeatherChart>) {
    this.dataChart = data;
    this.ngAfterViewInit();
  }

  public dataChart!: Array<WeatherChart>;
  private chart!: XYChart;
  public isMobile!: boolean;

  constructor(
    public anotherTranslateService: TranslateService,
    public trans: TranslateTypes,
    public zone: NgZone,
    public applicationService: ApplicationService
  ) {
    super(anotherTranslateService, applicationService);
  }

  public ngAfterViewInit() {
    this.zone.runOutsideAngular(async () => {
      if (this.chart) {
        this.chart.dispose();
      }
      const data = this.dataChart;
      // Call chart
      this.createChart(data);
    });
  }

  public createChart(chartData: any): void {
    /* Create Chart */
    this.createXYChart('weatherchart', this.am4charts.XYChart);
    this.xYChart.data = chartData;
    this.xYChart.dateFormatter.inputDateFormat = 'YYYY-MM-dd';

    // this.xYChart.zoomOutButton.disabled = true;
    // this.xYChart.cursor.behavior = 'none';

    /* Create Date Axis */
    const dateAxis = this.createBasicXYChartDateAxis();
    //dateAxis.tooltip.hiddenState.properties.opacity = 1;
    //dateAxis.tooltip.hiddenState.properties.visible = true;
    // let range = dateAxis.axisRanges.create();
    // range.date = new Date(2019, 0, 5);
    // range.endDate = new Date(2019, 0, 7);

    /* Create 2 Values Axis:
      1. Temperature and Precipitation
      2. Counts
    */
    const valueAxis = this.createBasicXYChartValueAxis();
    const valueAxis2 = this.createBasicXYChartValueAxis();
    valueAxis.max = 40;
    valueAxis.min = 0;
    valueAxis2.min = 0;
    valueAxis2.renderer.opposite = true;
    if (!this.onResize()) {
      // First value axis
      valueAxis.title.text = 'Temp. Med (ºC) e Precip. (mm)';
      // Second value axis
      valueAxis2.title.text = 'Capturas Totais';
    }

    /* Series 'precipitation' */
    var seriesPrecipitation = this.xYChart.series.push(new am4charts.ColumnSeries());
    seriesPrecipitation.dataFields.valueY = 'precipitation';
    //series.dataFields.categoryX = "dia";
    seriesPrecipitation.dataFields.dateX = 'date';
    seriesPrecipitation.name = 'Precipitação';
    seriesPrecipitation.tooltipText = '{name}: [bold]{valueY}[/] mm';
    seriesPrecipitation.clustered = false;
    seriesPrecipitation.fill = am4core.color('#050590');
    seriesPrecipitation.strokeWidth = 0;
    seriesPrecipitation.fillOpacity = 0.95;

    /* Series 'temperature' */
    var seriesTemperature = this.xYChart.series.push(new am4charts.ColumnSeries());
    seriesTemperature.dataFields.valueY = 'tmed';
    seriesTemperature.dataFields.dateX = 'date';
    seriesTemperature.name = 'Temperatura média';
    seriesTemperature.tooltipText = '{name}: [bold]{valueY}[/] ºC';
    seriesTemperature.clustered = false;
    seriesTemperature.fill = am4core.color('#bbbb00');
    seriesTemperature.strokeWidth = 0;
    seriesTemperature.fillOpacity = 0.5;

    /* Series 'count' */
    var seriesCount = this.xYChart.series.push(new am4charts.LineSeries());
    seriesCount.dataFields.valueY = 'count';
    //series3.dataFields.categoryX = "dia";
    seriesCount.dataFields.dateX = 'date';
    seriesCount.name = 'Capturas Totais';
    seriesCount.tooltipText = '{name}: [bold]{valueY}[/]';
    seriesCount.strokeWidth = 3;
    seriesCount.yAxis = valueAxis2;
    seriesCount.stroke = am4core.color('#000');
    seriesCount.fill = am4core.color('#000');
    seriesCount.strokeDasharray = '5';

    /* Add legend */
    this.xYChart.legend = new am4charts.Legend();
    this.xYChart.legend.height = 50;
    // chart.legend.align = "right";
    // chart.legend.itemContainers.template.paddingTop = 0;
    let markerTemplate = this.xYChart.legend.markers.template;
    markerTemplate.width = 15;
    markerTemplate.height = 15;

    /* Zooming */
    if (this.dataChart.length >= 29) {
      let init = this.dataChart[15].date;
      let end = this.dataChart[29].date;

      var cursor = new am4charts.XYCursor();
      cursor.behavior = 'panX';
      this.xYChart.cursor = cursor;
      cursor.lineX.disabled = true;

      this.xYChart.events.on('datavalidated', function () {
        dateAxis.zoomToDates(new Date(init), new Date(end)); // false, true
      });
    } else {
      this.xYChart.cursor = new am4charts.XYCursor();
    }

    /* RANGES */
    /* Create Date Ranges */
    function createRangeGrid(date: Date) {
      let range = dateAxis.axisRanges.create();
      range.date = date;
      range.grid.strokeOpacity = 0.6;
      range.grid.strokeWidth = 1;
      range.grid.strokeDasharray = '12,12';
      range.grid.fill = am4core.color('#333333');
      range.tick.disabled = false;
      range.tick.strokeOpacity = 0.5;
      range.tick.length = 50;
    }

    function createRangeLabels(dateString: string, dates: Array<string>) {
      const date = new Date(dateString + 'T00:00:00-0300');
      const dayBefore = new Date(date.getTime() - 24 * 60 * 60 * 1000);

      const weekIndex = dates.findIndex((d) => d == dateString);

      const firstDate = new Date(dates[0]);
      firstDate.setTime(firstDate.getTime() + 12 * 60 * 60 * 1000);
      let range1 = dateAxis.axisRanges.create();
      range1.endDate = date;
      range1.date = firstDate;
      const range1Sep = weekIndex > 1 ? '- '.repeat(3 * weekIndex) : '- '.repeat(weekIndex);
      range1.label.text = `${range1Sep} Semana ${getWeekNumber(dayBefore)} ${range1Sep}`;
      range1.label.paddingTop = 30;
      range1.label.location = 0.5;
      range1.label.horizontalCenter = 'middle';
      range1.label.fontWeight = 'lighter';
      range1.label.fontSize = 14;
      range1.grid.disabled = true;

      const lastDate = new Date(dates[dates.length - 1]);
      lastDate.setTime(lastDate.getTime() + 24 * 60 * 60 * 1000);
      let range2 = dateAxis.axisRanges.create();
      range2.endDate = lastDate;
      range2.date = date;
      const range2Sep =
        weekIndex < 5 ? '- '.repeat(3 * (6 - weekIndex)) : '- '.repeat(6 - weekIndex);
      range2.label.text = `${range2Sep} Semana ${getWeekNumber(range2.endDate)} ${range2Sep}`;
      range2.label.paddingTop = 30;
      range2.label.location = 0.5;
      range2.label.horizontalCenter = 'middle';
      range2.label.fontWeight = 'lighter';
      range2.label.fontSize = 14;
      range2.grid.disabled = true;
    }
    function getWeekNumber(date: Date): number {
      const startDate = new Date(date.getFullYear(), 0, 1);
      const days = Math.floor((date.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000));
      const weekNumber = Math.ceil((days + startDate.getDay()) / 7);
      return weekNumber;
    }

    const dates = this.dataChart.map((d) => d.date).sort((a, b) => a.localeCompare(b));
    for (const date of dates) {
      const dateObj = new Date(date + 'T00:00:00-0300');
      if (dateObj.getDay() == 1) {
        // Monday
        createRangeGrid(dateObj);
        createRangeLabels(date, dates);
      }
    }
    this.chart = this.xYChart;
  }

  public ngOnDestroy(): void {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

  //@HostListener('window:resize', ['$event'])
  public onResize() {
    if (window.innerWidth < 800) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    return this.isMobile;
  }
}
